import Setting from "../../setting.js";
import UsersTable from "@/components/UsersTable.vue";
import SmartTableRegistrations from "@/components/SmartTableRegistrations.vue";
import ExcelExport from "@/components/ExcelExport.vue";
import moment from "moment";
import { useAppStore } from "../stores/main";

export default {
  name: "UsersView",
  components: {
    UsersTable,
    SmartTableRegistrations,
    ExcelExport,
  },
  data: () => ({
    store: useAppStore(),
    loading: false,
    meeting: null,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Meetings",
        disabled: false,
        href: "/meetings",
      },
      {
        text: "Users",
        disabled: false,
      },
    ],
    items: [],
    itemsRegistrations: [],

    template: {
      id: "",
      data: {
        firstName: "",
        lastName: "",
        company: "",
        country: "",
        code: "",
        phone: "",
      },
    },
    fields: {
      Event: "event",
      FirstName: "firstName",
      LastName: "lastName",
      Email: "email",
      Company: "company",
      Country: "country",
      Code: "code",
      Phone: "phone",
      Status: "status",
      CreatedDate: "createdDate",
      UpdatedDate: "lastUpdateDate",
      Food: "food",
      From: "dateFrom",
      To: "dateTo",
    },
    fieldsRegistrations: {
      FirstName: "firstName",
      LastName: "lastName",
      Email: "email",
      Company: "company",
      Country: "country",
      Code: "code",
      Phone: "phone",
      Date: "date",
      "Session Name": "title",
      Room: "room",
      Status: "status",
      Registration: "registration",
      "Check-in": "checkin",
      From: "from",
      To: "to",
    },
    exportItems: [],
    exportItemsRegistrations: [],
    headers: [
      /*
      {
        text: "Event",
        align: "start",
        sortable: true,
        value: "data.eventname",
      },
      */
      {
        text: "FirstName",
        align: "start",
        sortable: true,
        value: "data.firstName",
      },
      {
        text: "LastName",
        align: "start",
        sortable: true,
        value: "data.lastName",
      },
      {
        text: "Email",
        align: "start",
        sortable: true,
        value: "data.email",
      },
      {
        text: "Company",
        align: "start",
        sortable: true,
        value: "data.company",
      },
      {
        text: "Country",
        align: "start",
        sortable: true,
        value: "data.country",
      },
      {
        text: "Country code",
        align: "start",
        sortable: true,
        value: "data.code",
      },

      {
        text: "Phone",
        align: "start",
        sortable: true,
        value: "data.phone",
      },
      {
        text: "Vegetarian food",
        align: "start",
        sortable: true,
        value: "data.food",
      },

      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "data.status",
      },

      {
        text: "From",
        align: "end",
        sortable: true,
        value: "data.dateFrom",
      },

      {
        text: "To",
        align: "end",
        sortable: true,
        value: "data.dateTo",
      },

      {
        text: "Created Date",
        align: "end",
        sortable: true,
        value: "createdDate",
      },
      {
        text: "Updated Date",
        align: "end",
        sortable: true,
        value: "lastUpdateDate",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersRegistrations: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },

      {
        text: "Room",
        align: "start",
        sortable: true,
        value: "location",
      },
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "title",
      },
      {
        text: "From",
        align: "start",
        sortable: true,
        value: "time.from",
      },
      {
        text: "To",
        align: "start",
        sortable: true,
        value: "time.to",
      },
      {
        text: "Registration",
        align: "start",
        sortable: true,
        value: "registrations",
      },
      {
        text: "Check-in",
        align: "start",
        sortable: true,
        value: "checkins",
      },
    ],
  }),
  async mounted() {
    if (!this.store.getAuth) {
      this.$router.push("/");
    }

    this.loading = true;
    const result = await this.getAll();
    let metting = await this.getMeeting();

    this.meeting = metting.data.data;

    this.items = result.data;

    this.exportItems = this.items.map((x) => {
      return {
        event: x.data.eventname ? x.data.eventname.replaceAll(",", ";") : "",
        firstName: x.data.firstName
          ? x.data.firstName.replaceAll(",", ";")
          : "",
        lastName: x.data.lastName ? x.data.lastName.replaceAll(",", ";") : "",
        email: x.data.email,
        company: x.data.company ? x.data.company.replaceAll(",", ";") : "",
        phone: x.data.phone,
        code: x.data.code,
        country: x.data.country,
        status: this.getStatus(x.data.status),
        createdDate: x.createdDate,
        updatedDate: x.lastUpdateDate,
        food: x.data.food,
        dateFrom: x.data.dateFrom,
        dateTo: x.data.dateTo,
      };
    });

    const resultRegistrations = await this.getAllRegistrations();
    this.itemsRegistrations = resultRegistrations.data;

    this.exportItemsRegistrations = [];

    this.itemsRegistrations
      .filter((x) => x.registrations.length > 0 || x.checkins.length > 0)
      .forEach((item) => {
        if (item.registrations.length > 0) {
          item.registrations.forEach((registration) => {
            this.exportItemsRegistrations.push({
              firstName: registration.firstName.replaceAll(",", ";"),
              lastName: registration.lastName.replaceAll(",", ";"),
              email: registration.email,
              company: registration.company.replaceAll(",", ";"),
              phone: registration.phone,
              code: registration.code,
              country: registration.country,
              date: moment(item.date).format("YYYY-MM-DD"),
              title: item.title,
              room: item.location,
              status: this.getStatus(registration.status),
              registration: "Registered",
              checkin: item.checkins.find((y) => y.email == registration.email)
                ? "Checked"
                : "",
              from: item.time.from,
              to: item.time.to,
            });
          });
        } else {
          item.checkins.forEach((checkin) => {
            this.exportItemsRegistrations.push({
              firstName: checkin.firstName,
              lastName: checkin.lastName,
              email: checkin.email,
              company: checkin.company,
              phone: checkin.phone,
              code: checkin.code,
              country: checkin.country,
              date: moment(item.date).format("YYYY-MM-DD"),
              title: item.title,
              room: item.location,
              status: this.getStatus(checkin.status),
              registration: "",
              checkin: "Checked",
              from: item.time.from,
              to: item.time.to,
            });
          });
        }
      });

    this.loading = false;
  },
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },
    getStatus(status) {
      let result = "";

      switch (status) {
        case 0:
          result = "On-site";

          break;
        case 1:
          result = "Remote";
          break;
        case 2:
          result = "Canceled";
          break;
      }

      return result;
    },
    onLogin() {
      if (this.password == this.passwordValue) {
        this.access = true;
      }
    },
    async getAll() {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/GetAllByMeeting",
        {
          syncAdd: null,
          meetingId: this.$route.params.id,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async getMeeting() {
      //console.warn(this.$route.params.id);

      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Admin/GetMeeting",
        {
          // withCredentials: true,
          params: {
            id: this.$route.params.id,
          },
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async getAllRegistrations() {
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Schedule/GetAllReqistrations",
        {
          // withCredentials: true,
          params: {
            meeting: this.$route.params.id,
          },
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async onUpdate(event) {
      /*
      console.warn({
        onUpdate: event,
      });*/

      await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/AdminUpdate",
        event.data.data,
        {
          // withCredentials: true,
        }
      );

      this.loading = true;
      const result = await this.getAll();
      this.loading = false;
      this.items = result.data;
    },
    async onInsert(event) {
      console.warn({
        onInsert: event,
      });

      await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/AdminAdd",
        event.data,
        {
          // withCredentials: true,
        }
      );

      this.loading = true;
      const result = await this.getAll();
      this.loading = false;
      this.items = result.data;
    },
    async onRemove(event) {
      var result;

      try {
        result = await this.axios.post(
          Setting[process.env.NODE_ENV].API + "Form/AdminRemove",
          event.data,
          {
            // withCredentials: true,
          }
        );
      } catch (error) {
        result = false;
      }

      if (!result) {
        this.loading = false;
      } else {
        this.loading = true;
        const result = await this.getAll();
        this.loading = false;
        this.items = result.data;
      }
    },
  },
};
